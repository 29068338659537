"use client"

import { SUPPORTED_LANGUAGES } from "@cyna/common/constants"
import { routes } from "@cyna/common/routes"
import { PageTitle } from "@cyna/components/base/Page"
import { Typo } from "@cyna/components/base/Typo"
import { Button } from "@cyna/components/ui/button"
import Layout from "@cyna/web/app/[lang]/(public)/layout"
import Loading from "@cyna/web/app/loading"
import { useI18n } from "@cyna/web/hooks/useI18n"
import { usePathname } from "next/navigation"

const urlLanguageRegex = new RegExp(`^/${SUPPORTED_LANGUAGES.join("|")}/`, "u")
const NotFoundPage = () => {
  const { t } = useI18n("public")
  const pathname = usePathname()
  const hasLanguage = urlLanguageRegex.test(pathname)

  return hasLanguage ? (
    <Layout>
      <PageTitle>{t("public:notFound.title")}</PageTitle>
      <Typo>
        <p>{t("public:notFound.description")}</p>
      </Typo>
      <Button asChild className="w-fit">
        <a href={routes.home}>{t("public:notFound.cta")}</a>
      </Button>
    </Layout>
  ) : (
    <Loading />
  )
}

export default NotFoundPage
