import { LoaderSpinner } from "@cyna/components/base/Loader"
import { Logo } from "@cyna/components/business/Logo"

const Loading = () => (
  <div className="absolute inset-0 flex flex-col items-center justify-center gap-4">
    <Logo className="text-primary w-32" />
    <LoaderSpinner className="size-8" />
  </div>
)

export default Loading
