import { cn } from "@cyna/components/lib/utils"
import { LoaderCircleIcon } from "lucide-react"
import { HTMLAttributes } from "react"

export const Loader = ({
  className,
  ...otherProps
}: { className?: string } & HTMLAttributes<HTMLDivElement>) => (
  <div
    {...otherProps}
    className={cn(
      "bg-background/40 fixed inset-0 z-50 flex w-full flex-col items-center justify-center",
      className,
    )}
  >
    <LoaderSpinner />
  </div>
)

export const LoaderSpinner = ({ className }: { className?: string }) => (
  <LoaderCircleIcon
    className={cn("h-6 w-6 animate-spin", className)}
    role="progressbar"
  />
)
