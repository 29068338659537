"use client"

import { Language, SUPPORTED_LANGUAGES } from "@cyna/common/constants"
import { routes } from "@cyna/common/routes"
import { Link } from "@cyna/components/base/Link"
import { Logo } from "@cyna/components/business/Logo"
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTriggerPrimitive,
} from "@cyna/components/ui/select"
import { useI18n } from "@cyna/web/hooks/useI18n"
import { useRouter } from "@cyna/web/hooks/useRouter"
import { LanguagesIcon } from "lucide-react"
import { ReactNode, useCallback } from "react"

const RootLayout = ({
  children,
}: Readonly<{
  children: ReactNode
}>) => {
  const [t, i18n] = useI18n()
  const router = useRouter()
  const changeLanguage = useCallback(
    (language: string) => {
      router.replace(window.location.pathname.slice(3), language as Language)
    },
    [router],
  )

  return (
    <>
      <div className="flex items-start p-4 sm:px-8">
        <Link href={routes.home}>
          <Logo className="text-primary w-24" />
        </Link>
        <Select value={i18n.language} onValueChange={changeLanguage}>
          <SelectTriggerPrimitive asChild>
            <button className="ml-auto flex w-fit gap-2 text-2xl">
              <LanguagesIcon className="h-6 w-6" />
            </button>
          </SelectTriggerPrimitive>
          <SelectContent>
            {SUPPORTED_LANGUAGES.map((lang) => (
              <SelectItem key={lang} value={lang}>
                {t(`language:name`, {
                  lng: lang,
                })}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
      <main className="flex h-full grow flex-col gap-4 p-0 sm:p-4">
        <div className="mx-auto w-fit">{children}</div>
      </main>
    </>
  )
}

export default RootLayout
